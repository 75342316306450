html {
  background-image: radial-gradient(
    circle,
    #633c71,
    #562f57,
    #46253f,
    #351b2b,
    #22131a,
    #1a0e13,
    #10070a,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000
  );
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 5px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #646464;
}

video::-webkit-media-controls-volume-slider {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}

.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

canvas {
  touch-action: auto !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
